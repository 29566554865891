import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { createClassSchema, editClassSchema } from './validation';
import {
  FOLDER_ITEM_TYPE_SECTION,
  STATUS_PUBLISHED
} from '@/pages/portal/products/constants';
import { t } from '@/utility/localization';
import { importNanoid } from '@/utility/helpers';
import { PRICE_TYPES } from '@/components/common/PricingSectionAndDiscounts/constants';
import { isValidNumber } from '@/pages/portal/utils/number';

export const MAX_CHUNK = 4;

export const THUMBNAIL = {
  video: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-video.png`,
  external_link: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-link.png`,
  file: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-files.png`,
  audio: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-audio.png`,
  image: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-image.png`
};

export const STATUS_VIDEO_PROCESSING = 'Processing';
export const FILE_ITEM_STATUS_ERROR = 'Error';

export const FILE_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const STATUS = {
  IDLE: 'idle',
  DELETING: 'deleting',
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded'
};

export const FILE_TYPE = {
  VIDEO: 'video',
  FILE: 'file',
  EXTERNAL_LINK: 'external_link',
  IMG: 'image',
  AUDIO: 'audio',
  SECTION: 'section'
};

export const PRODUCT_TYPE = {
  DIGITAL_PRODUCT: 'DIGITAL_PRODUCT',
  SESSION: 'SESSION'
};

export const SCREEN = {
  FOLDER: 'folder',
  FOLDER_ITEMS: 'folder_items',
  FOLDER_ITEMS_EDIT: 'folder_items_edit'
};

export const FOLDER_EVENT = {
  CREATE: 'create',
  EDIT: 'edit'
};

export const DEFAULT_CLASS_FIELD_VALUES = {
  type: PRODUCT_TYPE.CLASS,
  event: FOLDER_EVENT.CREATE,
  title: '',
  description: '',
  thumbnailFile: null,
  thumbnail: '',
  tags: [],
  access: 'paid',
  amount: 500,
  newDiscountsToApply: [],
  discountsToDisable: []
};

export const CONTENT_TEMPLATE_ID = {
  NO_CATEGORY_PRODUCT: 'creative_storytelling',
  NO_CATEGORY_COURSES: 'social_media_mastery',
  NO_CATEGORY_SESSION: 'brand_consultation',
  FINANCE_PRODUCT: 'finance_goals_tracker',
  FINANCE_COURSES: 'personal_finance_course',
  FINANCE_SESSION: 'budget_planning_session',
  CAREER_PRODUCT: 'career_toolkit_bundle',
  CAREER_COURSES: 'portfolio_building',
  CAREER_SESSION: 'resume_review',
  FITNESS_PRODUCT: 'home_workout_guide',
  FITNESS_COURSES: 'strength_yoga',
  FITNESS_SESSION: 'fitness_training',
  NUTRITION_PRODUCT: 'healthy_eating',
  NUTRITION_COURSES: 'nutrition_science',
  NUTRITION_SESSION: 'meal_planning',
  RELIGION_PRODUCT: 'meditation_audio',
  RELIGION_COURSES: 'world_religions',
  RELIGION_SESSION: 'spiritual_consultation',
  NEW: 'new'
};

export const DEFAULT_TEMPLATE_ID_TO_BANNER_IMAGE_MAP = {
  [CONTENT_TEMPLATE_ID.NEW]: `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-yellow-01.png`
  // [CONTENT_TEMPLATE_ID.COURSE]: `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-yellow-02.png`,
  // [CONTENT_TEMPLATE_ID.EBOOK]: `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-orange-03.png`,
  // [CONTENT_TEMPLATE_ID.RESOURCE]: `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-blurple-01.png`
};

const CONTENT_TEMPLATE_ID_TO_DEFAULT_AMOUNT_MAP = {
  [CONTENT_TEMPLATE_ID.NO_CATEGORY_PRODUCT]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.NO_CATEGORY_COURSES]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.NO_CATEGORY_SESSION]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.FINANCE_PRODUCT]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.FINANCE_COURSES]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.FINANCE_SESSION]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.CAREER_PRODUCT]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.CAREER_COURSES]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.CAREER_SESSION]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.FITNESS_PRODUCT]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.FITNESS_COURSES]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.FITNESS_SESSION]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.NUTRITION_PRODUCT]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.NUTRITION_COURSES]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.NUTRITION_SESSION]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.RELIGION_PRODUCT]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.RELIGION_COURSES]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  },
  [CONTENT_TEMPLATE_ID.RELIGION_SESSION]: {
    USD: 30,
    INR: 1000,
    BRL: 100,
    MXN: 300,
    DEFAULT: 0
  }
};

const getDefaultAmountForTemplate = (templateId, currency) => {
  try {
    return (
      CONTENT_TEMPLATE_ID_TO_DEFAULT_AMOUNT_MAP[templateId][currency] ||
      CONTENT_TEMPLATE_ID_TO_DEFAULT_AMOUNT_MAP[templateId]['DEFAULT']
    );
  } catch (e) {
    return 99;
  }
};

export const getDefaultCreateFolderFormPlaceholders = (
  templateId,
  currency
) => {
  const defaultProductDesc = t(
    'describe-the-product-they-are-buying-and-why-they-should-buy-it-tip-use-ai-as-a-start-use-emojis-or-bullet-points-to-list-down-whats-included-product-benefits-and-special-offers'
  );
  const defaultCourseDesc = t(
    'describe-the-course-they-are-buying-and-why-they-should-buy-it-tip-use-ai-as-a-start-use-emojis-or-bullet-points-to-list-down-whats-included-product-benefits-and-special-offers'
  );
  const defaultSessionDesc = t(
    'describe-the-session-they-are-buying-and-why-they-should-buy-it-tip-use-ai-as-a-start-use-emojis-or-bullet-points-to-list-down-whats-included-product-benefits-and-special-offers'
  );

  switch (templateId) {
    case CONTENT_TEMPLATE_ID.NO_CATEGORY_PRODUCT: {
      return {
        title: t('product-name'),
        description: defaultProductDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.NO_CATEGORY_COURSES: {
      return {
        title: t('course-name'),
        description: defaultCourseDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.NO_CATEGORY_SESSION: {
      return {
        title: t('session-name'),
        description: defaultSessionDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.FINANCE_PRODUCT: {
      return {
        title: t('product-name'),
        description: defaultProductDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.FINANCE_COURSES: {
      return {
        title: t('finance-course-name'),
        description: defaultCourseDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.FINANCE_SESSION: {
      return {
        title: t('session-name'),
        description: defaultSessionDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.CAREER_PRODUCT: {
      return {
        title: t('product-name'),
        description: defaultProductDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.CAREER_COURSES: {
      return {
        title: t('course-name'),
        description: defaultCourseDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.CAREER_SESSION: {
      return {
        title: t('session-name'),
        description: defaultSessionDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.FITNESS_PRODUCT: {
      return {
        title: t('product-name'),
        description: defaultProductDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.FITNESS_COURSES: {
      return {
        title: t('fitness-course-name'),
        description: defaultCourseDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.FITNESS_SESSION: {
      return {
        title: t('session-name'),
        description: defaultSessionDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.NUTRITION_PRODUCT: {
      return {
        title: t('product-name'),
        description: defaultProductDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.NUTRITION_COURSES: {
      return {
        title: t('nutrition-course-name'),
        description: defaultCourseDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.NUTRITION_SESSION: {
      return {
        title: t('session-name'),
        description: defaultSessionDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.RELIGION_PRODUCT: {
      return {
        title: t('product-name'),
        description: defaultProductDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.RELIGION_COURSES: {
      return {
        title: t('course-name'),
        description: defaultCourseDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.RELIGION_SESSION: {
      return {
        title: t('session-name'),
        description: defaultSessionDesc,
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
    case CONTENT_TEMPLATE_ID.NEW:
    default: {
      return {
        title: t('clear-product-name'),
        description: t(
          'describe-the-product-they-are-buying-and-why-they-should-buy-it-n-ntip-use-ai-as-a-start-use-emojis-or-bullet-points-to-list-down-whats-included-product-benefits-and-special-offers'
        ),
        amount: getDefaultAmountForTemplate(templateId, currency)
      };
    }
  }
};

export const getDefaultCreateFolderData = (templateId) => {
  return {
    templateId,
    type: PRODUCT_TYPE.DIGITAL_PRODUCT,
    event: FOLDER_EVENT.CREATE,
    title: '',
    description: '',
    thumbnailFile: null,
    thumbnail:
      DEFAULT_TEMPLATE_ID_TO_BANNER_IMAGE_MAP[CONTENT_TEMPLATE_ID.NEW],
    tags: [],
    newDiscountsToApply: [],
    discountsToDisable: []
  };
};

export const getFleNameWithoutExtension = ({ fileName }) => {
  try {
    return fileName.replace(/\.[^/.]+$/, '');
  } catch (e) {
    console.error(e);
    return fileName;
  }
};

export const reorderList = ({ list, startIndex, endIndex }) => {
  try {
    const tempList = [...list];
    const [removed] = tempList.splice(startIndex, 1);
    tempList.splice(endIndex, 0, removed);

    return tempList;
  } catch (e) {
    console.error(e);
    return list;
  }
};

export const getVideoDurationOfAFile = (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.onerror = function () {
      reject('error');
    };
    video.src = URL.createObjectURL(file);
  });
};

export const getAudioDurationOfAFile = (file) => {
  return new Promise((resolve, reject) => {
    const audio = document.createElement('audio');
    audio.preload = 'metadata';
    audio.onloadedmetadata = function () {
      window.URL.revokeObjectURL(audio.src);
      resolve(audio.duration);
    };
    audio.onerror = function () {
      reject('error');
    };
    audio.src = URL.createObjectURL(file);
  });
};

export const addIndexAndClientIdToFolderItems = (folderItems) => {
  const { nanoid } = importNanoid();
  try {
    return folderItems.map((item, index) => {
      return {
        ...item,
        index: index + 1,
        clientId: item?.clientId || nanoid()
      };
    });
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const addIndexAndClientIdToFolderItemsRecursively = (
  folderItems
) => {
  const { nanoid } = importNanoid();
  try {
    return folderItems.map((folderItem, index) => {
      let formattedData = {
        ...folderItem,
        index: index + 1,
        clientId: folderItem?.clientId || nanoid()
      };

      if (folderItem.type === FOLDER_ITEM_TYPE_SECTION) {
        formattedData.items = addIndexAndClientIdToFolderItems(
          folderItem.items || []
        );
      }

      return formattedData;
    });
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const reorder = (list, dragStartIndex, dragEndIndex) => {
  const result = JSON.parse(JSON.stringify(list));

  // remove the dragged item from the original position
  const [draggedItem] = result.splice(dragStartIndex, 1);

  // place the dragged item in the new position
  result.splice(dragEndIndex, 0, draggedItem);

  // return reordered list
  return result;
};

export const getFolderItemDataFormatted = ({
  list,
  parentSectionId = null
}) => {
  try {
    return Promise.all(
      list.map(async (item) => {
        let type = item.type;
        let payload = {};

        if (parentSectionId) {
          payload = { parentSectionId };
        }
        switch (type) {
          case FILE_TYPE.EXTERNAL_LINK: {
            return {
              ...payload,
              type: item.type,
              title: 'Link',
              description: '',
              link: item.link,
              isDeleted: false,
              isEditing: true,
              isChanged: true
            };
          }
          case FILE_TYPE.VIDEO:
          case FILE_TYPE.FILE:
          case FILE_TYPE.AUDIO:
          case FILE_TYPE.IMG: {
            let videoDuration;
            let audioDuration;
            let payloadForFile = {
              ...payload,
              type: item.type,
              title:
                getFleNameWithoutExtension({
                  fileName: item?.file?.name
                }) ?? '',
              description: '',
              isDeleted: false,
              isEditing: true,
              isChanged: true,
              //keep file at the bottom because theres a BE limitation, for ref: https://github.com/expressjs/multer/issues/299
              file: item.file,
              format: item.file.type,
              size: item.file.size
            };
            if (item.type === FILE_TYPE.VIDEO) {
              videoDuration = await getVideoDurationOfAFile(item.file);
              // the duration will be in seconds, so convert it to minutes in the BE or on FE while displaying
              payloadForFile = {
                ...payloadForFile,
                duration: videoDuration
              };
            }
            if (item.type === FILE_TYPE.AUDIO) {
              audioDuration = await getAudioDurationOfAFile(item.file);
              // the duration will be in seconds, so convert it to minutes in the BE or on FE while displaying
              payloadForFile = {
                ...payloadForFile,
                duration: audioDuration
              };
            }
            return payloadForFile;
          }
          case FILE_TYPE.SECTION: {
            return {
              type: item.type,
              title: item.title,
              description: item.description,
              // parentSectionId: null, // Type section cannot be a child of another section
              isDeleted: false,
              isEditing: true,
              isChanged: true
            };
          }
        }
      })
    );
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getFormattedItemData = ({ item }) => {
  switch (item.type) {
    case FILE_TYPE.SECTION: {
      return {
        _id: item._id,
        type: item.type,
        title: item.title,
        description: item.description,
        items: item.items,
        clientId: item.clientId,
        file: null, // keep file at the bottom because theres a BE limitation, for ref: https://github.com/expressjs/multer/issues/299
        parentSectionId: item.parentSectionId
      };
    }
    case FILE_TYPE.EXTERNAL_LINK: {
      return {
        _id: item._id,
        type: item.type,
        link: item.link,
        title: item.title,
        description: item.description,
        icon: null,
        thumbnail: item.thumbnail ?? THUMBNAIL[item.type],
        clientId: item.clientId,
        file: null, // keep file at the bottom because theres a BE limitation, for ref: https://github.com/expressjs/multer/issues/299
        parentSectionId: item.parentSectionId
      };
    }
    case FILE_TYPE.FILE:
    case FILE_TYPE.VIDEO:
    case FILE_TYPE.IMG:
    case FILE_TYPE.AUDIO: {
      let fileSchema = {
        _id: item._id,
        type: item.type,
        link: item.link,
        title: item.title,
        description: item.description,
        icon: null,
        duration: item.duration ?? 0,
        format: item.format,
        size: item.size,
        thumbnail: item.thumbnail ?? THUMBNAIL[item.type],
        clientId: item.clientId,
        file: item.file, // keep file at the bottom because theres a BE limitation, for ref: https://github.com/expressjs/multer/issues/299
        parentSectionId: item.parentSectionId
      };

      if (item.type !== FILE_TYPE.VIDEO && item.type !== FILE_TYPE.AUDIO) {
        delete fileSchema['duration'];
      }
      if (item.signedUrlId) {
        fileSchema = {
          ...fileSchema,
          signedUrlId: item.signedUrlId
        };
      }
      return fileSchema;
    }
  }
};

export const getFormattedParams = ({ itemOrderList, itemMap }) => {
  try {
    // lets check if create or update
    const changedItems = itemOrderList
      .map((itemId, index) => {
        const indexValue = index + 1;
        const itemData = itemMap[itemId];

        if (itemData.isChanged) {
          const formattedItemData = getFormattedItemData({
            item: itemData
          });
          // eslint-disable-next-line no-unused-vars
          const { index, ...formattedItemDataWithoutIndex } =
            formattedItemData;

          return { index: indexValue, ...formattedItemDataWithoutIndex };
        }

        return null;
      })
      .filter((item) => !!item);

    return changedItems;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getFormattedFileParams = ({ list }) => {
  try {
    return list.map((item) => {
      const formattedItemData = getFormattedItemData({
        item
      });

      return {
        // index: index + 1,
        ...formattedItemData,
        index: item.index,
        status: STATUS_PUBLISHED
      };
    });
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const isFileSupported = (type) => {
  return [
    FILE_TYPE.FILE,
    FILE_TYPE.VIDEO,
    FILE_TYPE.IMG,
    FILE_TYPE.AUDIO
  ].includes(type);
};

export const getPayloadForSignedURL = ({
  item,
  totalNumberOfChunks,
  isMulti
}) => {
  return {
    mimeType: item?.file?.type,
    numberOfChunks: totalNumberOfChunks,
    originalFileName: item?.file?.name,
    typeOfFile: item?.type,
    ...(isMulti && { isMultipart: true })
  };
};

export const MIN_CHUNK_SIZE = 10485760;
export const getHasFolderItemsChanged = ({ list }) => {
  try {
    return list.some((item) => item?.isChanged);
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getFolderValidationSchema = ({ folderEvent }) => {
  if (folderEvent === FOLDER_EVENT.CREATE) return createClassSchema;
  if (folderEvent === FOLDER_EVENT.EDIT) return editClassSchema;
};

export const getFolderParams = ({ data, currency }) => {
  const amount =
    isValidNumber(data?.amount) && data?.priceType === PRICE_TYPES.FIXED
      ? data?.amount * 100
      : null;

  const folderEvent = data.event;

  const flexiblePricingDetails = {
    minAmount: isValidNumber(data?.minAmount)
      ? data?.minAmount * 100
      : null,
    suggestedAmount: isValidNumber(data?.suggestedAmount)
      ? data?.suggestedAmount * 100
      : null
  };
  if (folderEvent === FOLDER_EVENT.CREATE) {
    let folderObjectSchema = {
      type: data?.type,
      templateId: data?.templateId,
      currency,
      title: data?.title?.trim(),
      amount,
      description: data?.description,
      tags: data?.tags,
      thumbnail:
        data?.thumbnailFile ??
        data?.thumbnail ??
        `${STATIC_ASSET_BASE_URL}/nasIO/portal/png/default-library-folder.png`,
      ...(data?.priceType === PRICE_TYPES.FIXED && {
        newDiscountsToApply: data?.newDiscountsToApply ?? []
      }),
      priceType: data?.priceType,
      ...flexiblePricingDetails
    };
    return folderObjectSchema;
  }
  if (folderEvent === FOLDER_EVENT.EDIT) {
    return {
      type: data?.type,
      currency,
      title: data?.title?.trim(),
      amount,
      description: data?.description,
      tags: data?.tags,
      thumbnail:
        data?.thumbnailFile ??
        data?.thumbnail ??
        `${STATIC_ASSET_BASE_URL}/nasIO/portal/png/default-library-folder.png`,
      ...(data?.priceType === PRICE_TYPES.FIXED && {
        newDiscountsToApply: data?.newDiscountsToApply ?? [],
        discountsToDisable: data?.discountsToDisable ?? []
      }),
      priceType: data?.priceType,
      ...flexiblePricingDetails
    };
  }
  return;
};

export const exists = ({ value }) => {
  try {
    return typeof value !== 'undefined' && value !== null;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getUpdateIndicesOfFolderItemsMap = (
  folderItem,
  folderItemsOrder
) => {
  // if the folder item is not changed, we don't need to update the index
  // lets update the indices of folder items with the order
  return {
    ...folderItem,
    index: folderItemsOrder.indexOf(folderItem?.clientId) + 1
  };
};

export const calculateTotalNumberOfChunks = (file) => {
  const numberOfChunks = Math.floor(file.size / 10485760);
  return numberOfChunks > MAX_CHUNK ? MAX_CHUNK : numberOfChunks;
};

export const getFormattedLogTextForMaxFilesUpload = (
  activeCommunityId,
  maxFiles,
  folderId,
  userId
) => {
  return `User ${userId} tried to upload ${maxFiles} files in folder ${folderId} in community ${activeCommunityId}`;
};
export const getFormattedTextForFileNotSupported = (
  activeCommunityId,
  errorMessage,
  folderId,
  userId
) => {
  return `User ${userId} tried to upload unsupported file in folder ${folderId} in community ${activeCommunityId}. Error: ${errorMessage}`;
};

export const getFormattedTextForFileItemsToBeUploaded = (
  activeCommunityId,
  stringifiedFileItems,
  folderId,
  userId
) => {
  return `User ${userId} is trying to upload ${stringifiedFileItems} in folder ${folderId} in community ${activeCommunityId}`;
};
export const SORT_STATE_NEWEST = 'Newest';
export const SORT_STATE_OLDEST = 'Oldest';
export const SORT_STATE_DEFAULT = 'Default';
export const videoTypes = ['video/mp4', 'video/quicktime'];
export const imageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
export const acceptedTypes = [
  ...videoTypes,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  ...imageTypes,
  'audio/mpeg',
  'application/zip',
  'text/csv',
  'text/plain',
  'audio/wav',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const DEFAULT_CONTENT_LIBRARY_IMAGES = [
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-yellow-01.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-yellow-02.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-yellow-03.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-yellow-04.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-blurple-01.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-blurple-02.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-blurple-03.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-blurple-04.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-orange-01.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-orange-02.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-orange-03.png`,
  `${STATIC_ASSET_BASE_URL}/nasIO/community-folder/folder-item-thumbnails/content-thumbnail-orange-04.png`
];
